import { LINEAR_ABSOLUTE_END_URL_PARAM, LINEAR_ABSOLUTE_START_URL_PARAM } from "@l2r-front/l2r-networks"

import { APP_CITY } from "../constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../constants/modules"

export const getModulesBreadcrumb = (searchUrlParams = {}, _) => {
    searchUrlParams.delete(LINEAR_ABSOLUTE_START_URL_PARAM)
    searchUrlParams.delete(LINEAR_ABSOLUTE_END_URL_PARAM)

    return [
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road`,
            newPath: `${MODULE_VERTICAL_SIGNING}?${searchUrlParams.toString()}`,
        },
    ]
}