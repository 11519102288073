import { useRoutes } from "react-router-dom"

import { MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { MobileVerticalSigningPage } from "../../pages/MobileVerticalSigningPage"

export const VerticalSigningMobileRoutes = () => {

    const roadwayRoutes = useRoutes([
        {
            path: "/*",
            element: <>
                <MapAdapterBoundingBox />
                <MobileVerticalSigningPage />
            </>,
        },
    ])

    return roadwayRoutes
}