import { useMemo } from "react"

import { useLocation, useNavigate, matchRoutes } from "react-router-dom"

import { useUrlParams } from "../utils/useUrlParams"

export const useHandleBack = (getBreadcrumbPaths) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { getParams } = useUrlParams()

    const breadcrumbPaths = useMemo(() => {
        const params = getParams()
        return getBreadcrumbPaths(params, location)
    }, [getParams, getBreadcrumbPaths, location])

    const matches = matchRoutes(breadcrumbPaths, location)

    const newPath = useMemo(() => {
        if (matches) {
            const newRoutes = matches[0].route.newPath
            const routeParts = newRoutes.split("/")
            const matchedRoutesParts = routeParts.map(rp => {
                if (rp.startsWith(":")) {
                    const matchString = rp.slice(1)
                    return matches[0].params[matchString]
                }

                return rp
            })
            return matchedRoutesParts.join("/")
        }
        return "/"
    }, [matches])

    return () => navigate(newPath)
}

