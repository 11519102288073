import { useMatch, useParams } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer } from "@l2r-front/l2r-images"
import { NetworkReferentialLayer } from "@l2r-front/l2r-networks"

import { APP_CITY } from "../../../../common/constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../../../common/constants/modules"

export function VerticalSigningMapLayersRouter() {

    const match = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road`)
    const params = useParams()
    const road = params?.road || params?.["*"]
    const isSegmentView = !!match

    return (
        <>
            <NetworkReferentialLayer />
            {isSegmentView && <ImageCollectionLayer road={road} />}
            {isSegmentView && <ImageSelectedLayer />}
        </>
    )
}