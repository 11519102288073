import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { Sidebar as L2RSidebar } from "@l2r-front/l2r-ui"

import { VerticalSigningSidebar } from "../../containers/VerticalSigningSidebar"

export function VerticalSigningSidebarRouter() {

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <VerticalSigningSidebar /> },
    ]), [])

    return <L2RSidebar>{useRoutes(sidebarRoutes)}</L2RSidebar>
}
