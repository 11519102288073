import { useEffect } from "react"

import { useQueries, useQuery } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "../constants/apis"
import { useAlertsDispatchContext } from "../contexts/AlertsContext"

export async function queryFn(
    axiosInstance,
    url,
    queryParameters,
    config,
) {
    const response = await axiosInstance.get(url, {
        params: { ...queryParameters },
        ...config,
    })
    return response.data
}

export function useL2rQuery(url, queryKey, queryParameters, config = {}, initialData) {
    const {
        fetchConfig,
        onError,
        errorSnackbarMessage,
        ...queryConfig
    } = (config || {})

    const { setSnackbar } = useAlertsDispatchContext()

    const result = useQuery({
        queryKey,
        queryFn: () => queryFn(axiosInstance, url, queryParameters, fetchConfig),
        ...queryConfig,
        initialData,
    })

    useEffect(() => {
        if (result.isError) {
            onError?.()
            if (errorSnackbarMessage) {
                setSnackbar(errorSnackbarMessage)
            }
        }
    }, [result.isError, onError, errorSnackbarMessage, setSnackbar])

    return result
}

export function useL2rQueries(queriesData) {
    const queries = queriesData?.map(queryData => {
        const {
            fetchConfig,
            ...queryConfig
        } = (queryData.config || {})
        return {
            queryKey: queryData.queryKey,
            queryFn: () => queryFn(axiosInstance, queryData.url, queryData.queryParameters, fetchConfig),
            ...queryConfig,
        }
    })

    return useQueries({ queries: queries || [] })
}