import { useCallback } from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { LINEAR_ABSOLUTE_START_URL_PARAM, LINEAR_ABSOLUTE_END_URL_PARAM } from "../constants/urlParams"

export const useNavigateToLinearLocation = () => {
    const { getParams, setParams } = useUrlParams()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = getParams()
    const params = useParams()
    const currentRoad = params?.road || params?.["*"]

    return useCallback((linearLocation, baseUrl, options = {}) => {
        const isDifferentLocation = linearLocation.road !== currentRoad
            || !(options.useStartEnd && location.search.includes(`LINEAR_ABSOLUTE_START_URL_PARAM=${linearLocation.start}`))
            || !(options.useStartEnd && location.search.includes(`LINEAR_ABSOLUTE_END_URL_PARAM=${linearLocation.end}`))

        if (isDifferentLocation) {
            const urlParams = new URLSearchParams()
            if (options.useStartEnd) {
                urlParams.append(LINEAR_ABSOLUTE_START_URL_PARAM, linearLocation.start)
                urlParams.append(LINEAR_ABSOLUTE_END_URL_PARAM, linearLocation.end)
            }
            const paramsToRemove = options.paramsToRemove || []
            for (const [key, value] of searchParams.entries()) {
                if (key !== LINEAR_ABSOLUTE_START_URL_PARAM && key !== LINEAR_ABSOLUTE_END_URL_PARAM && !paramsToRemove.includes(key)) {
                    urlParams.append(key, value)
                }
            }

            if (options.saveMapBoundingBox) {
                storeMapBoundingBox()
            }
            setParams(urlParams)
            const url = `${baseUrl}/${linearLocation.road}?${urlParams.toString()}`
            navigate(url)
        }

    }, [location.search, navigate, searchParams, setParams, storeMapBoundingBox, currentRoad])
}