import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

export const VerticalSigningDesktopRoutes = () => {

    const verticalSigningRoutes = useMemo(() => ([
        {
            path: "/",
            element: <MapAdapterBoundingBox />,
        },
    ]), [])

    return useRoutes(verticalSigningRoutes)
}