import {
    useL2rMutation,
} from "@l2r-front/l2r-query"

export function useAuthMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `auth/${url}`

    return useL2rMutation(
        apiUrl,
        axiosFn,
        queryParameters,
        config,
    )
}
