import { useQueryClient } from "@tanstack/react-query"

import { STATUS_CODE_FORBIDDEN } from "../constants/httpStatus"
import { useL2rQuery, useL2rQueries } from "./useL2rQuery"

export const useAuthenticatedQuery = (url, queryKey, queryParameters, config, initialData) => {
    const queryClient = useQueryClient()
    const query = useL2rQuery(url, queryKey, queryParameters, config, initialData)
    if (query?.error?.response?.status === STATUS_CODE_FORBIDDEN) {
        queryClient.invalidateQueries({ queryKey: ["user"] })
        queryClient.refetchQueries({ queryKey: ["user"] })
    }
    return query
}

export function useAuthenticatedQueries(queriesData) {
    const queryClient = useQueryClient()
    const queries = useL2rQueries(queriesData)
    queries?.forEach(query => {
        if (query?.error?.response?.status === STATUS_CODE_FORBIDDEN) {
            queryClient.invalidateQueries({ queryKey: ["user"] })
            queryClient.refetchQueries({ queryKey: ["user"] })
        }
    })

    return queries
}