import { useL2rMutation } from "@l2r-front/l2r-query"

export function useRoadworksMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `roadwork/${url}`

    return useL2rMutation(
        apiUrl,
        axiosFn,
        { ...queryParameters },
        {
            ...config,
        },
    )
}