import { useEffect } from "react"

import { useParams } from "react-router-dom"

import {
    useGeoServerFeaturesList,
} from "@l2r-front/l2r-geodata"
import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import {
    LINEAR_ABSOLUTE_START_URL_PARAM,
    LINEAR_ABSOLUTE_END_URL_PARAM,
} from "@l2r-front/l2r-networks"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { useRoadwayStateContext, useRoadwayDispatchContext } from "../../contexts/RoadwayContext"
import { networkGradingsQueryKeys } from "../../hooks/queries/networkGradings/queryKeys"

export function RoadwayDetailSegmentSelectListener() {

    const { getParam } = useUrlParams()
    const { selectedNetworkYearlyStatus, selectedSegmentUuid } = useRoadwayStateContext()
    const { setSelectedSegment } = useRoadwayDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()

    const { road } = useParams()
    const absStart = getParam(LINEAR_ABSOLUTE_START_URL_PARAM)
    const absEnd = getParam(LINEAR_ABSOLUTE_END_URL_PARAM)
    const queryKeyParams = { road, absStart, absEnd }

    const { data: selectedNetworkGradingsFeatures } = useGeoServerFeaturesList(
        networkGradingsQueryKeys.list({ ...queryKeyParams, layer: selectedNetworkYearlyStatus?.layer }),
        {
            layer: selectedNetworkYearlyStatus?.layer,
            linear_location_road: road,
            linear_location_start: absStart,
            linear_location_end: absEnd,
        },
        {
            enabled: !!(road && selectedNetworkYearlyStatus?.layer),
        })

    useEffect(() => {
        const networkGradingFeature = selectedNetworkGradingsFeatures?.[0]
        if (!networkGradingFeature) {
            return
        }

        const segmentFeature = {
            ...networkGradingFeature,
            properties: {
                ...networkGradingFeature.properties,
                linearLocation: JSON.stringify(networkGradingFeature.linear_locations),
            },
        }
        if (!selectedSegmentUuid && !!segmentFeature) {
            setSegmentFeatureIdClicked(segmentFeature.uuid)
            setSelectedSegment(segmentFeature)
        }
    }, [selectedNetworkGradingsFeatures, selectedSegmentUuid, setSelectedSegment, setSegmentFeatureIdClicked])
}