import { styled } from "@l2r-front/l2r-ui"

import { MobileVerticalSigningMap as VerticalSigningMap } from "../../containers/MobileVerticalSigningMap"
import { VerticalSigningSidebar as L2RVerticalSigningSidebar } from "../../containers/VerticalSigningSidebar"

export const VerticalSigningSidebar = styled(L2RVerticalSigningSidebar)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(VerticalSigningMap)({
    height: "100%",
    width: "100%",
})
