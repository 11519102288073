import { ReactMapGl, Map as L2rMap } from "@l2r-front/l2r-map"
import { RoadSearch } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

export function Map(props) {

    const {
        children,
        ...mapProps
    } = props

    return (
        <L2rMap
            dragRotate={false}
            topLeftElements={<RoadSearch />}
            isLoading={false}
            {...mapProps}
        >
            {children}
        </L2rMap>
    )
}

Map.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}