import { useTranslation } from "@l2r-front/l2r-i18n"
import { SidebarContent } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

export function VerticalSigningSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])

    return (
        <SidebarContent id="verticalSigning-sidebar" title={t(I18N_NAMESPACE, "containers.verticalSigningSidebar.title")}>
        </SidebarContent>
    )
}