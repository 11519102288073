import { CityContentRouter } from "../../routers/CityContentRouter"
import { CityDesktopMapRouter } from "../../routers/CityDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"
import * as Styled from "./CityDesktopLayout.styled"

export function CityDesktopLayout() {

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <CityContentRouter />
            <Styled.Map>
                <CityDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
